import React from 'react';

import { PricingPlanDetails } from '../interfaces/royalty';
import { getPricingPlanDetails } from '../services/api/royalty-api';
import { selectPricingPlanUuid } from '../services/store/userSlice';
import { useAppSelector } from './redux';

export function useFetchPricingPlan() {
  const pricingPlanUuid = useAppSelector(selectPricingPlanUuid);
  const [plan, setPlan] = React.useState<PricingPlanDetails | null>(null);

  React.useEffect(() => {
    if (!pricingPlanUuid) return;

    const fetchData = async () => {
      try {
        const { data } = await getPricingPlanDetails(pricingPlanUuid);
        setPlan(data);
      } catch (error) {
        console.error('Error fetching pricing plan:', error);
      }
    };
    fetchData();
  }, [pricingPlanUuid]);

  return { plan };
}
