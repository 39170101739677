import { SectionTitle, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Footer, Header } from '../../components';
import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamPageTitle } from '../../components/shared';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../services/store/userSlice';
import { $lightBlue } from '../../styles/colors';
import { LINK_PERMISSIONS } from '../../utils/constants/auth0-roles';
import { checkAuthorizationAccess } from '../../utils/functions/helpers';

function Dashboard() {
  const { t } = useTranslation('dashboard', { keyPrefix: 'dashboard' });
  const user = useAppSelector(selectUser);

  return (
    <>
      <Header />
      <ContainerOutlet>
        <VamPageTitle
          subTitle={t('tag')}
          title={t('title')}
          breadcrumbRoutes={[
            {
              breadcrumb: t('title'),
              path: '#',
            },
          ]}
          detailsLabelBox={[
            {
              label: t('detailLabel', { firstname: user.firstName }),
            },
          ]}
        />
        <Grid2
          container
          rowSpacing={3}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{
            marginTop: 1,
            backgroundColor: $lightBlue,
          }}
        >
          {LINK_PERMISSIONS.map((category, index) => {
            // Filter links based on user's permissions
            const accessibleLinks = category.links.filter((link) =>
              checkAuthorizationAccess(user.permissions, link.permission)
            );

            // Skip the card if there are no accessible links
            if (accessibleLinks.length === 0) return null;

            // Determine grid columns based on the number of links
            const linkGridStyle = {
              display: 'grid',
              gridTemplateColumns: accessibleLinks.length > 6 ? 'repeat(2, 1fr)' : '1fr', // Two columns if more than 6 links
              gap: '8px',
              listStyle: 'none', // Remove default list styling
              padding: 0, // Remove default padding
            };

            return (
              <Grid2 size={4} key={index}>
                <ContainerCard
                  width="100%"
                  maxWidth="100%"
                  height="100%"
                  avatarHeaderNode={
                    <SectionTitle title={category.title} description={category.description} />
                  }
                  dataTestId={`${category.title.toLowerCase().replace(/\s+/g, '-')}-links`}
                >
                  <ul style={linkGridStyle}>
                    {accessibleLinks.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <Link to={link.path} className="tw-no-underline">
                          <Typo variant="subtitle1" data-testid={`${link.label}-link-test-id`}>
                            &bull; {link.label}
                          </Typo>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </ContainerCard>
              </Grid2>
            );
          })}
        </Grid2>
      </ContainerOutlet>
      <Footer />
    </>
  );
}

export default Dashboard;
