import { SimpleButton, Tooltip, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { ContentCopy, DeleteOutlined } from '@mui/icons-material';
import { FormControlLabel, Grid2, Switch } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DistinctProductLabelValue } from '../../interfaces/distinct-product-label-value';
import { getTitleContext, partTypes } from '../../pages/royalties/utils';
import { fetchDistinctProduct } from '../../services/api/veg-api';
import { $primaryNavy } from '../../styles/colors';
import { JobType, TypeOfPart } from '../../utils/constants/royalties';
import { mapToProductFilter, removeFalsyProperty, toOptionsFilters } from '../../utils/functions';
import { FormSelect } from '../shared';

interface SideProps {
  sideNb: number;
  onRemove: Function;
  handleSwitchChange: Function;
  handleCopy: Function;
}

export function Side({ sideNb, onRemove, handleSwitchChange, handleCopy }: SideProps) {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob.jobDetails' });
  const methods = useFormContext();
  const { getValues, watch, setValue } = methods;
  const jobType = watch('job_type');
  const typeOfPart = watch('type_of_part');
  const specialBevel = watch(`displayed_sides.${sideNb}.special_bevel`);
  const orientedConnection = watch(`displayed_sides.${sideNb}.oriented_connection`);
  const partType = partTypes.find((partType) => partType.type === typeOfPart);
  const { nbSides, nbPin, nbBox, specialConnections } = partType;
  const { update, replace } = useFieldArray({ name: 'sides' });

  const [distinctProductLabelValue, setDistinctProduct] = React.useState({
    connection: [],
    od_inch: [],
    weight: [],
    end: [],
    application: [],
    connection_type: [],
  } as DistinctProductLabelValue);

  React.useEffect(() => {
    const fetchData = async () => {
      await handleChangeDistinctProduct();
    };
    fetchData();
  }, []);

  // Update sides if specialConnections and connection is selected for the side
  // and typeOfPart is not Other and jobType is NEW PART
  React.useEffect(() => {
    if (
      specialConnections?.length &&
      getValues(`displayed_sides.${sideNb}.connection`)?.length &&
      typeOfPart !== TypeOfPart.OTHER &&
      jobType === JobType.NEW_PART
    ) {
      const connectionType = distinctProductLabelValue?.connection_type[0]?.value;
      const specialConnection = specialConnections.find(
        (sc) => sc.connectionType === connectionType
      );
      let idx = sideNb;
      // Add PIN sides
      while (idx < specialConnection.nbPin) {
        update(idx, { ...getValues(`sides.${sideNb}`), end: 'PIN' });
        idx++;
      }
      // Add BOX sides
      while (idx < specialConnection.nbPin + specialConnection.nbBox) {
        update(idx, { ...getValues(`sides.${sideNb}`), end: 'BOX' });
        idx++;
      }
    }
  }, [distinctProductLabelValue?.connection_type, specialBevel, orientedConnection]);

  /* istanbul ignore next */
  const handleChangeDistinctProduct = async (controlName?: string) => {
    // If connection is changed, set special_bevel back to false to avoid
    // sending true if selected before
    if (controlName === 'connection') {
      setValue(`displayed_sides.${sideNb}.special_bevel`, false);
      setValue(`sides.${sideNb}.special_bevel`, false);
      replace(getValues(`displayed_sides`));
    }
    const formValues = getValues(`displayed_sides.${sideNb}`);
    const filter = removeFalsyProperty(mapToProductFilter(formValues, ['weight']));
    try {
      const { data } = await fetchDistinctProduct(filter);
      const labelValueOptions = toOptionsFilters(data);
      setDistinctProduct(labelValueOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const titleContext = getTitleContext(getValues('displayed_sides')?.length);

  const shouldDisableEndSelect =
    jobType !== JobType.REPAIR &&
    nbSides !== null && // Check if nbSides is defined
    // Check if nbPin or nbBox >= nbSides
    (nbPin >= nbSides || nbBox >= nbSides);

  // Check if nbPin and nbBox are null and there is specialConnections
  const shouldHideEndSelect = !!(
    jobType !== JobType.REPAIR &&
    nbPin === null &&
    nbBox === null &&
    specialConnections?.length
  );

  const handleFieldSelect = (controlName: string) => {
    const value = getValues(`displayed_sides.${sideNb}.${controlName}`);
    if (controlName === 'od_inch') {
      setValue(`sides.${sideNb}.${controlName}`, parseFloat(value));
    } else {
      setValue(`sides.${sideNb}.${controlName}`, value);
    }
    handleChangeDistinctProduct(controlName);
  };

  return (
    <>
      <Grid2 size={{ xs: 12 }}>
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5">
          <Typo
            className="tw-whitespace-nowrap"
            variant="body1"
            fontWeight={500}
            color={$primaryNavy}
          >
            {t('sideNb', { index: sideNb + 1, context: titleContext })}
          </Typo>
          <div className="tw-h-0.5 tw-w-full tw-bg-gray-200" />
          <Tooltip arrow placement="top-end" title={t('copyTooltip')} variant="light">
            <div>
              <SimpleButton
                variant="icon-secondary-dark-blue"
                size="small"
                onClick={() => handleCopy(sideNb)}
                // We can copy side only if there is more than one side
                // or if jobType is REPAIR or typeOfPart is Other
                // Otherwise, sides and values are determined by typeOfPart
                disabled={
                  jobType !== JobType.REPAIR && typeOfPart !== TypeOfPart.OTHER && nbSides <= 1
                }
                data-testid="side-btn-copy"
              >
                <ContentCopy fontSize="small" />
              </SimpleButton>
            </div>
          </Tooltip>
          <SimpleButton
            size="small"
            variant="remove-red"
            onClick={() => onRemove(sideNb)}
            data-testid="side-btn-remove"
          >
            <DeleteOutlined fontSize="small" />
          </SimpleButton>
        </div>
      </Grid2>
      {!shouldHideEndSelect ? (
        <Grid2 size={{ xs: 4 }}>
          <FormSelect
            id="end-select"
            data-testid="end-select"
            variant="standard"
            name={`displayed_sides.${sideNb}.end`}
            label={t('form.end')}
            options={distinctProductLabelValue?.end}
            handleChange={() => handleFieldSelect('end')}
            disabled={shouldDisableEndSelect}
          />
        </Grid2>
      ) : null}
      <Grid2 size={{ xs: 4 }}>
        <FormSelect
          id="connection-select"
          data-testid="connection-select"
          variant="standard"
          name={`displayed_sides.${sideNb}.connection`}
          label={t('form.connection')}
          options={distinctProductLabelValue?.connection}
          handleChange={() => handleFieldSelect('connection')}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }}>
        <FormSelect
          id="od_inch-select"
          data-testid="od_inch-select"
          variant="standard"
          name={`displayed_sides.${sideNb}.od_inch`}
          label={t('form.diameter')}
          options={distinctProductLabelValue?.od_inch}
          handleChange={() => handleFieldSelect('od_inch')}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }}>
        <FormSelect
          id="weight-select"
          data-testid="weight-select"
          variant="standard"
          name={`displayed_sides.${sideNb}.weight`}
          label={t('form.weight')}
          options={distinctProductLabelValue?.weight}
          handleChange={() => handleFieldSelect('weight')}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }}>
        <FormSelect
          id="application-select"
          data-testid="application-select"
          variant="standard"
          name={`displayed_sides.${sideNb}.application`}
          label={t('form.application')}
          options={distinctProductLabelValue?.application}
          handleChange={() => handleFieldSelect('application')}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', gap: '20px' }}>
        <FormControlLabel
          control={
            <Switch
              checked={getValues(`displayed_sides.${sideNb}.oriented_connection`)}
              onChange={(_, checked: boolean) =>
                handleSwitchChange(sideNb, 'oriented_connection', checked)
              }
              data-testid="side-oriented-connection-switch"
            />
          }
          label={t('form.orientedConnection')}
        />
        {/* Display special bevel switch only if connection_type is T&C */}
        {distinctProductLabelValue?.connection_type?.find(
          (connectionType) => connectionType.value === 'T&C'
        ) && getValues(`displayed_sides.${sideNb}.connection`) ? (
          <FormControlLabel
            control={
              <Switch
                checked={getValues(`displayed_sides.${sideNb}.special_bevel`)}
                onChange={(_, checked: boolean) =>
                  handleSwitchChange(sideNb, 'special_bevel', checked)
                }
                data-testid="side-special-bevel-switch"
              />
            }
            label={t('form.specialBevel')}
          />
        ) : null}
      </Grid2>
    </>
  );
}
