import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { ContentCopy } from '@mui/icons-material';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SuccessIcon from '../../../assets/icons/icon-success.svg';
import { redirectTo } from '../../../utils/functions';
import { VamDialog } from '../VamDialog/VamDialog';

interface DialogSaveJobProps {
  jobId: number;
  isOpen: boolean;
  setIsOpen: Function;
  urlToReturn: string;
  handleCloseDialog: Function;
}

export function DialogSaveJob({
  jobId,
  isOpen,
  setIsOpen,
  urlToReturn,
  handleCloseDialog,
}: DialogSaveJobProps) {
  const { t } = useTranslation('royalties', { keyPrefix: 'job.dialog' });
  const textRef = React.useRef<HTMLInputElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleCopy = () => {
    if (textRef.current) {
      textRef.current.select();
      navigator.clipboard.writeText(textRef.current.value);
    }
  };

  return (
    <VamDialog
      title="Declaration Royalty "
      headerIcon={<img src={SuccessIcon} alt="support-switch-status-icon" />}
      isOpen={isOpen}
      withCloseButton={true}
      handleClose={() => {
        handleCloseDialog();
        setIsOpen(false);
      }}
      dialogActions={
        <DialogActions className="!tw-justify-center">
          <SimpleButton
            data-testid="copy-job-dialog-btn"
            variant="secondary-dark"
            size="small"
            startIcon={<ContentCopy />}
            onClick={handleCopy}
          >
            {t('copyJobBtn')}
          </SimpleButton>
          <SimpleButton
            data-testid="redirect-job-listings-btn"
            variant="cta"
            size="small"
            onClick={() => {
              setIsOpen(false);
              redirectTo(urlToReturn, navigate, {
                state: { from: location },
              });
            }}
          >
            {t('goListingBtn')}
          </SimpleButton>
        </DialogActions>
      }
    >
      <div className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-4">
        <div className="tw-font-bold">
          <Trans
            components={{
              strong: <span className="tw-font-bold tw-text-primary-brand"></span>,
            }}
          >
            {t('successOnSave', {
              jobId: jobId,
            })}
          </Trans>
        </div>
        <input type="hidden" ref={textRef} value={jobId} />
        <div>
          <span>{t('description')}</span>
        </div>
      </div>
    </VamDialog>
  );
}
