import { GridPaginationModel } from '@mui/x-data-grid';

import {
  DistinctConnectionLabelValue,
  DistinctProductLabelValue,
} from '../../interfaces/distinct-product-label-value';
import {
  ApplicabilityProductDistinct,
  ApplicabilityProductGradeDistinct,
} from '../../interfaces/documentation';
import { PlantJobsDistinct } from '../../interfaces/royalty-plant';
import { DistinctConnectionApi } from '../../interfaces/set-model';
import { DistinctProductApi } from '../../interfaces/veg';
import { OD_MAPPING } from '../constants';
import { arrayToLabelValues } from './label-value';
import { removeDuplicateFromArray, round } from './object';

export function toOptionsFilters(
  data:
    | DistinctProductApi
    | DistinctConnectionApi
    | ApplicabilityProductDistinct
    | ApplicabilityProductGradeDistinct
    | PlantJobsDistinct
): DistinctConnectionLabelValue {
  return Object.entries(data).reduce((currentItem, [key, value]) => {
    if (key === 'od_inch') {
      const od_label_value = (value as number[]).map((val) => {
        const castValue = typeof val === 'string' ? parseFloat(val) : val;
        const od_round = round(castValue, 3);
        const od_label = od_round !== null ? OD_MAPPING[od_round] : null;
        return {
          label: od_label || round(castValue, 3)?.toString() + '"',
          value: castValue,
        };
      });
      return { ...currentItem, [key]: od_label_value };
    } else if (key === 'weight') {
      return { ...currentItem, [key]: arrayToLabelValues(removeDuplicateFromArray(value)) };
    } else {
      return { ...currentItem, [key]: arrayToLabelValues(value) };
    }
  }, {}) as DistinctProductLabelValue;
}

export function toConnectionsOptionsFilters(
  data: DistinctProductApi,
  minWeight: number,
  maxWeight: number
): DistinctConnectionLabelValue {
  const distinctProduct = toOptionsFilters(data);
  distinctProduct['max_weight'] = minWeight
    ? distinctProduct['weight'].filter((item) => +item.value >= minWeight)
    : distinctProduct['weight'];
  distinctProduct['min_weight'] = maxWeight
    ? distinctProduct['weight'].filter((item) => +item.value <= maxWeight)
    : distinctProduct['weight'];

  return distinctProduct;
}

export function handleFilterSubmit(
  column: string,
  value: string,
  filters: any[],
  setFilters: Function,
  setPaginationModel: Function,
  paginationModel: GridPaginationModel
) {
  if (filters.find((filter) => filter.field === column)) {
    setFilters(
      filters.map((filter) => ({
        ...filter,
        value: filter.field === column ? value.toUpperCase() : filter.value,
      }))
    );
  } else {
    const filter = [{ field: column, value: value.toUpperCase() }];
    setFilters((filters) => [...filters, ...filter]);
  }
  setPaginationModel({ ...paginationModel, page: 0 });
}
