import {
  GridColDef,
  GridRenderCellParams,
  /*, getGridStringOperators*/
} from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { VegCase } from '../../interfaces/case';
import { OD_MAPPING, VegWebsiteStatus } from '../../utils';

export const gaugeModelColumns: GridColDef[] = [
  {
    field: 'designation_vam',
    headerName: 'vam® services designation',
    minWidth: 250,
    flex: 3,
    // note: by default headerAlign and align are in center
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'drawing_number',
    headerName: 'drawing',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'reference_number',
    headerName: 'ref. number',
    minWidth: 120,
    flex: 1,
  },
];

export const searchGaugeModelColumns: GridColDef[] = [
  {
    field: 'designation_vam',
    headerName: 'vam® services designation',
    minWidth: 220,
    headerAlign: 'left',
    align: 'left',
    hideSortIcons: true,
    //filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    field: 'drawing_number',
    headerName: 'drawing',
    flex: 1,
    minWidth: 100,
    hideSortIcons: true,
    //filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    field: 'reference_number',
    headerName: 'ref. number',
    flex: 1,
    minWidth: 120,
    hideSortIcons: true,
    //filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
];

export const updateSelectedCase = (
  setState: Dispatch<SetStateAction<VegCase[]>>,
  setValue: UseFormSetValue<FieldValues>,
  trigger: UseFormTrigger<FieldValues>,
  caseSelected: VegCase[]
) => {
  const case_id = caseSelected?.[0]?.case_id;
  setValue('case_id', case_id);
  trigger('case_id');
  setState(caseSelected);
};

export const applicabilitiesCols: GridColDef[] = [
  {
    field: 'connection',
    headerName: 'CONNECTION',
    flex: 1,
  },
  {
    field: 'od_inch',
    headerName: 'OD',
    flex: 1,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, number>) =>
      OD_MAPPING?.[params.value] || params.value,
  },
  {
    field: 'weight_thickness',
    headerName: 'W/T',
    flex: 1.5,
  },
  { field: 'end', headerName: 'END', flex: 1.5 },
  {
    field: 'application',
    headerName: 'APPLICATION',
    flex: 1.5,
  },
  {
    field: 'option',
    headerName: 'OPTION',
    flex: 1.5,
  },
];

export function isRequiredModified(originalItems: any, modifiedItems: any): boolean {
  for (const original of originalItems) {
    const modified = modifiedItems.find((item) => item.gauge_model_id === original.id);
    if (modified && original.is_gauge_required !== modified.required) {
      return true;
    }
  }
  return false;
}

export function isSelectGaugeDisabled(
  websiteStatus: VegWebsiteStatus,
  params: GridRenderCellParams<any, any>
) {
  return (
    websiteStatus !== VegWebsiteStatus.Draft &&
    !params.row.is_gauge_required &&
    !params.row.initialGaugeRequired
  );
}

export function convertGaugeRequiredToApi(value): boolean {
  switch (value) {
    case 'null':
      return null;
    case 'true':
    case true:
      return true;
    case 'false':
    case false:
      return false;
  }
}
