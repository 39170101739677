export function isNotNullOrUndefined(value): boolean {
  return value !== undefined && value !== null;
}

export function isNonNullObject(obj: any): boolean {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function isNeitherUndefinedNorNull(value: any): boolean {
  return typeof value !== 'undefined' && value !== null;
}

export function strToNumber(strValue: string): number | string {
  return strValue && +removeSpaces(strValue);
}

export function removeSpaces(str: string): string {
  return str?.replace(/\s/g, '');
}

export function round(value: number, decimals = 2): number | null {
  return Number.isFinite(value) ? +value.toFixed(decimals) : null;
}

export function deepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export function removeFalsyProperty(object: Record<string, any>): Record<string, any> {
  // this fn will remove all falsy values except 0
  return Object.keys(object)
    .filter((key) => object[key] === 0 || object[key])
    .reduce((prev, curr) => {
      prev[curr] = object[curr];
      return prev;
    }, {});
}

export function hasAnyProperty(object: Record<string, any>): boolean {
  return !!Object.keys(object).length;
}

export function removeDuplicateFromArray<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

export function removeFalsyPropertyFromArrays(
  object: Record<string, any[]>
): Record<string, any[]> {
  // this fn will remove all empty Arrays from our object
  return Object.keys(object)
    .filter((key) => object[key] && object[key].length)
    .reduce((prev, curr) => {
      prev[curr] = object[curr];
      return prev;
    }, {});
}

export function removeObjectKey<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  key: K
): Omit<T, K> {
  const { [key]: _, ...rest } = obj;

  return rest;
}

export function removeObjectKeys<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: string[]
): Omit<T, K> {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key))) as Omit<
    T,
    K
  >;
}

export function removeDuplicateIdsFromArray(arr: any[]): any[] {
  const seenIds: { [key: number]: boolean } = {};

  return arr.filter((item) => {
    if (seenIds[item.id]) {
      return false;
    }
    seenIds[item.id] = true;
    return true;
  });
}

export function isArrayNotEmpty(arr: any[]): boolean {
  return arr && arr?.length > 0;
}
