import { array, number, object, string } from 'zod';

import { ONLY_NUMERIC, REQUIRED_FIELD } from './validators-message';

const gaugeModelRequiredSchema = object({
  gauge_model_id: number(),
  required: string(),
});

const applicationsSchema = object({
  connection: string(),
  od_inch: number(),
  weight: number(),
  end: string(),
  application: string(),
  option: string(),
  thickness: number(),
});

export const formSchema = object({
  connection: string().min(1, { message: REQUIRED_FIELD }),
  od_inch: number(),
  end: string().min(1, { message: REQUIRED_FIELD }),
  min_weight: number(),
  max_weight: number(),
  application: string().min(1, { message: REQUIRED_FIELD }),
  option: string().min(1, { message: REQUIRED_FIELD }),
  case_id: number(),
  applications: array(applicationsSchema).nonempty(),
  gauge_models: array(gaugeModelRequiredSchema).nonempty(),
  applicabilities_connection: string().optional(),
  applicabilities_od: number().nullable().optional(),
  total_weight: string()
    .optional()
    .nullable()
    // Using .refine() for custom validation.
    // The regular expression checks if the value contains only digits.
    .refine((value) => /^\d+$/.test(value), {
      message: ONLY_NUMERIC,
    }),
});

export enum CaseStatus {
  Active = 'active',
  Draft = 'draft',
  Inactive = 'inactive',
}
