import { UserDetails, UserStore } from '../../interfaces/user';
import { INTERNAL_LICENSEE_TYPE, PLANT_LICENSEE_TYPE } from '../constants/user';

export function mappingUserToState(user: UserStore): UserDetails {
  const moreInformations = {};
  // If licensee type is not plant, we add pricing plan uuid and ab pricing plan uuid
  if (user.licensee_type !== PLANT_LICENSEE_TYPE) {
    moreInformations['pricingPlanUuid'] = user.pricing_plan_uuid;
    moreInformations['abPricingPlanUuid'] = user.ab_pricing_plan_uuid;
  }
  if (user.licensee_type === INTERNAL_LICENSEE_TYPE) {
    moreInformations['depotId'] = user.depot_id;
  } else {
    moreInformations['defaultDeliveryAddressId'] = user.default_delivery_address_id;
    moreInformations['invoicingAddressPoId'] = user.invoicing_address_po_id;
    moreInformations['invoicingAddressNotPoId'] = user.invoicing_address_not_po_id;
    moreInformations['preferredPickupAddressId'] = user.preferred_pickup_address_id;
  }
  return {
    auth0Id: user.auth0_id,
    idUserMyVam: user.id,
    licenseeNumber: user.licensee_number,
    licenseeName: user.licensee_name,
    hasManyLicensees: user.hasManyLicensees,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    job: user.job,
    phoneNumber: user.phone_number,
    licenseeType: user.licensee_type,
    allowExport: user.allow_export,
    geographicalArea: user.geographical_area,
    workshopName: user.workshop_name,
    keyContactId: user.key_contact_id,
    ...moreInformations,
    roles: user.roles,
    permissions: user.permissions,
  };
}

export function mappingUserToStore(user: UserDetails): UserStore {
  const moreInformations = {};
  // If licensee type is not plant, we add pricing plan uuid and ab pricing plan uuid
  if (user.licenseeType !== PLANT_LICENSEE_TYPE) {
    moreInformations['pricing_plan_uuid'] = user.pricingPlanUuid;
    moreInformations['ab_pricing_plan_uuid'] = user.abPricingPlanUuid;
  }
  if (user.licenseeType === INTERNAL_LICENSEE_TYPE) {
    moreInformations['depot_id'] = user.depotId;
  } else {
    moreInformations['default_delivery_address_id'] = user.defaultDeliveryAddressId;
    moreInformations['invoicing_address_po_id'] = user.invoicingAddressPoId;
    moreInformations['invoicing_address_not_po_id'] = user.invoicingAddressNotPoId;
    moreInformations['preferred_pickup_address_id'] = user.preferredPickupAddressId;
  }
  return {
    auth0_id: user.auth0Id,
    id: user.idUserMyVam,
    licensee_number: user.licenseeNumber,
    licensee_name: user.licenseeName,
    hasManyLicensees: user.hasManyLicensees,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    job: user.job,
    phone_number: user.phoneNumber,
    licensee_type: user.licenseeType,
    allow_export: user.allowExport,
    geographical_area: user.geographicalArea,
    workshop_name: user.workshopName,
    key_contact_id: user.keyContactId,
    ...moreInformations,
    roles: user.roles,
    permissions: user.permissions,
  };
}

export function getMyVamName(userDetails: UserDetails): string {
  return `${userDetails?.firstName} ${
    userDetails?.lastName ? userDetails?.lastName?.[0] + '.' : ''
  }`;
}
