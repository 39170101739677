import { AxiosResponse } from 'axios';

import { PricingPlan, PricingPlanDetails } from '../../interfaces/royalty';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttp } from '../axios-services/http-veg';

/**
 * Get the pricing plans name and uuid
 * @returns Promise PricingPlan[]
 */
export function getPricingPlansNameUuid(): Promise<AxiosResponse<PricingPlan[], any>> {
  return vegHttp.get<PricingPlan[]>(endpoints.pricing_plans_uuid_name);
}

/** Get the pricing plan details
 * @param pricingPlanUuid string
 * @returns Promise PricingPlanDetails
 */
export function getPricingPlanDetails(
  pricingPlanUuid: string
): Promise<AxiosResponse<PricingPlanDetails, any>> {
  return vegHttp.get<PricingPlanDetails>(endpoints.pricing_plan_by_uuid(pricingPlanUuid));
}
