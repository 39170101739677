import { SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import {
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks';
import { Licensee } from '../../../interfaces/context';
import {
  BookingDistinctFiltersParams,
  BookingsByFiltersParams,
  ManageBookingGrid,
} from '../../../interfaces/veg';
import { exportCsvRentalData, getBookings } from '../../../services/api/veg-api';
import { selectUser } from '../../../services/store/userSlice';
import {
  BOOKING_STATUS_COLORS,
  BOOKING_STATUS_GRID_MAPPING,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_MODEL,
} from '../../../utils';
import {
  formatDate,
  mapToProductFilter,
  mapToProductFilterFromArrays,
  removeFalsyProperty,
  removeFalsyPropertyFromArrays,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions/helpers';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { BoxContainer, CountResultFilter, VamFilter } from '../../shared';
import { ManageBookingCommon, QuickAccessButtonProps } from '../manage-booking/ManageBookingCommon';
import { manageBookingColumns } from '../utils';
import { GlobalSearchFilter } from './GlobalSearchFilter';
import { FormSchemaType, defaultValues, formSchema } from './GlobalSearchFilterForm';

export function GlobalSearch() {
  const { t } = useTranslation('gauge', { keyPrefix: 'globalSearchBooking' });
  const { t: tGauge } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const user = useAppSelector(selectUser);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [totalRows, setTotalRows] = React.useState<number>(0);
  const [loadingGrid, setLoadingGrid] = React.useState<boolean>(false);
  const [bookings, setBookings] = React.useState<ManageBookingGrid[]>([]);
  const [dataFilter, setDataFilter] = React.useState<BookingDistinctFiltersParams>({
    gauge_location_id: user.depotId ? [user.depotId] : [],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const location = useLocation();
  const navigate = useNavigate();
  // added licensees state here to allow reset on this field
  const [licensees, setLicensees] = React.useState<Licensee[]>([]);

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, getValues } = methods;

  React.useEffect(() => {
    const offset = paginationModel.page * paginationModel.pageSize;

    fetchGetBookings({
      data_filter: dataFilter,
      pagination: { skip: offset, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  }, [paginationModel.page, sortModel]);

  const fetchGetBookings = async (params: BookingsByFiltersParams) => {
    setLoadingGrid(true);
    getBookings(params)
      .then(({ data }) => {
        const bookings = data?.bookings?.map((row: ManageBookingGrid, idx: number) => ({
          ...row,
          id: idx,
          creation_date: formatDate(row.creation_date, false),
          modification_date: formatDate(row.modification_date, false),
          start_date: formatDate(row.start_date, false),
          end_date: formatDate(row.end_date, false),
          grid_status: BOOKING_STATUS_GRID_MAPPING[row.status],
        }));
        setTotalRows(data?.count);
        setBookings(bookings);
      })
      .catch((error) => {
        console.error('🚀 ~ file: GlobalSearch.tsx ~ fetch ~ error', error);
      })
      .finally(() => {
        setLoadingGrid(false);
      });
  };

  const applyFilter: SubmitHandler<FormSchemaType> = async (data) => {
    const licenseeNumber = getValues('licensee_number');
    const filter = {
      ...removeFalsyPropertyFromArrays(
        mapToProductFilterFromArrays(data as Record<string, any[]>, [
          'status',
          'weight_thickness',
          'gauge_location_id',
          'business_number',
        ]) as Record<string, any[]>
      ),
      ...removeFalsyProperty(
        mapToProductFilter({ period_start: data.period_start, period_end: data.period_end }, [
          'period_start',
          'period_end',
        ])
      ),
      licensee_number: licenseeNumber ? [licenseeNumber] : [],
    };
    setDataFilter(filter);
    const skipPage = paginationModel.page * paginationModel.pageSize;
    await fetchGetBookings({
      data_filter: filter,
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  };

  async function redirectToBookingDetail(row: ManageBookingGrid) {
    const { booking_id, business_number } = row;
    redirectTo(`../booking/${booking_id}`, navigate, {
      state: { from: location, business_number },
    });
  }

  /* istanbul ignore next */
  const accessButtons: QuickAccessButtonProps[] = [
    {
      title: tGauge('manage_booking.quickAccessButtons.booking.title', {
        context: 'currentRentals',
      }),
      subtitle: tGauge('manage_booking.quickAccessButtons.booking.subtitle', {
        context: 'currentRentals',
      }),
      dataTestId: 'vam-global-currentRentals-quick-access-button',
      handleClick: () =>
        redirectTo('../reservation-management', navigate, { state: { from: location } }),
    },
    {
      title: tGauge('manage_booking.quickAccessButtons.booking.title', {
        context: 'manageBooking',
      }),
      subtitle: tGauge('manage_booking.quickAccessButtons.booking.subtitle', {
        context: 'manageBooking',
      }),
      dataTestId: 'vam-manageBooking-quick-access-button',
      handleClick: () => redirectTo('../current-rentals', navigate, { state: { from: location } }),
    },
  ];

  const downloadCSV = async () => {
    exportCsvRentalData({
      data_filter: dataFilter,
      pagination: { skip: 0, limit: totalRows },
      sort: sortModel,
    })
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const filename = 'export_rental_data_results.csv';
        saveAs(blob, filename);
        enqueueSnackbar(tCommon('notification.successExport'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <ManageBookingCommon
        title={tGauge('manage_booking.title', { context: 'globalSearch' })}
        subtitle={tGauge('tag')}
        breadcrumbRoutes={[
          {
            breadcrumb: tGauge('manage_booking.title', { context: 'globalSearch' }),
            path: '#',
          },
        ]}
        accessButtons={accessButtons}
      />
      <FormProvider {...methods}>
        <VamFilter
          title={t('titleFilter')}
          subTitle={t('subTitleFilter')}
          handleApplyFilter={handleSubmit(applyFilter)}
          disableApplyBtn={loadingGrid}
          handleApplyReset={() => setLicensees([])} // FIXME this is not good to handle like this parent should change the state child
          defaultValues={{
            ...defaultValues,
            gauge_location_id: [user.depotId],
          }}
        >
          <GlobalSearchFilter licensees={licensees} setLicensees={setLicensees} />
        </VamFilter>
      </FormProvider>
      <BoxContainer>
        <Stack
          className="tw-pb-8"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <CountResultFilter className="tw--ml-4" count={totalRows} />
          <SimpleButton
            variant="secondary-dark"
            data-testid="btn-csv-download-bookings-management"
            onClick={() => downloadCSV()}
          >
            {tCommon('downloadResults')}
          </SimpleButton>
        </Stack>
        <VamDataGrid
          sx={{
            '.MuiBox-root': {
              height: 'auto',
            },
          }}
          initialStateColumns={{
            columnVisibilityModel: {
              modification_date: false,
            },
          }}
          columns={manageBookingColumns.concat([
            {
              field: 'status',
              headerName: 'STATUS',
              renderCell: /* istanbul ignore next */ (
                params: GridRenderCellParams<any, BookingStatus>
              ) => (
                <Typo
                  variant="body2"
                  color={BOOKING_STATUS_COLORS[params.value]}
                  fontWeight={500}
                  className="text-ellipsis tw-inline-flex"
                >
                  {tGauge(`manageBookingDetail.changeStatusDialog.status.${params.value}`)}
                </Typo>
              ),
              minWidth: 120,
              flex: 1,
            },
          ])}
          rows={bookings}
          height={500}
          disableColumnFilter
          onRowDoubleClick={({ row }: GridRowParams) => {
            /* istanbul ignore next */
            redirectToBookingDetail(row);
          }}
          rowCount={totalRows}
          showRowLeftThickBorder
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="server"
          keepNonExistentRowsSelected
          sortingMode="server"
          filterMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </BoxContainer>
    </ContainerOutlet>
  );
}
