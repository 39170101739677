import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';

import { SetGaugeModel, SetGaugeModelUI } from '../../interfaces/set-creation';
import { GridStatus } from '../data-grid/utils';

export enum GaugeError {
  REQUIRED_SERIAL_NUMBER = 'requiredSerialNumber',
  DUPLICATED_SERIAL_NUMBER = 'duplicatedSerialNumber',
  EXISTS = 'exists',
  LENGTH = 'length',
  REQUIRED_GAUGE_NOT_PRESENT = 'requiredGaugeNotPresent',
}

export const setContentColumns = (with_damage_column: boolean): GridColDef[] => [
  {
    field: 'designation_vam',
    headerName: 'vam® services designation',
    flex: 2,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'drawing_number',
    headerName: 'drawing',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'reference_number',
    headerName: 'ref. N°',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'is_present',
    headerName: 'present',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <Checkbox readOnly value={value || undefined} checked={value} />;
    },
    flex: 1,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'is_gauge_required',
    headerName: 'required',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <span>{value ? 'Yes' : 'No'}</span>;
    },
    flex: 1,
    type: 'boolean',
  },
  {
    field: 'serial_number',
    headerName: 'serial n°',
    flex: 1,
    editable: true,
    valueFormatter: /* istanbul ignore next */ (value: string) => value && value.toUpperCase(),
  },
  with_damage_column && {
    field: 'is_damaged',
    headerName: 'is damaged',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <Checkbox readOnly value={value || undefined} checked={value} />;
    },
    flex: 1,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'next_calibration_date',
    headerName: 'next calibration date',
    type: 'date',
    flex: 2,
    width: 280,
    editable: true,
    renderCell: /* istanbul ignore next */ (params) =>
      params.value ? format(params.value, 'dd/MM/yyyy') : null,
  },
];

export function hasRequiredGaugeError(gaugeModel: SetGaugeModelUI): boolean {
  return gaugeModel.is_gauge_required && !gaugeModel.is_present;
}

export function hasRequiredSerialNumberError(gaugeModel: SetGaugeModelUI): boolean {
  return gaugeModel.is_present && !gaugeModel.serial_number;
}

export function hasLengthError(gaugeModel: SetGaugeModelUI): boolean {
  return gaugeModel.serial_number?.length > 50;
}

export function isSerialNumberDuplicated(
  gaugeModel: SetGaugeModelUI,
  gaugeModels: SetGaugeModelUI[]
): boolean {
  return gaugeModels.some(
    (gauge) =>
      gauge.id !== gaugeModel.id &&
      gauge.serial_number?.toUpperCase() === gaugeModel.serial_number?.toUpperCase() &&
      gauge.drawing_number?.toUpperCase() === gaugeModel.drawing_number?.toUpperCase() &&
      gauge.reference_number?.toUpperCase() === gaugeModel.reference_number?.toUpperCase()
  );
}

export function updateGauges(gaugeModels: SetGaugeModelUI[]): SetGaugeModelUI[] {
  return gaugeModels.map((gaugeModel) => {
    let error: GaugeError | null = null;

    // 1. Check if gauge is required but not present
    if (hasRequiredGaugeError(gaugeModel)) {
      error = GaugeError.REQUIRED_GAUGE_NOT_PRESENT;
    }
    // 2. Check for missing serial number if gauge is present
    else if (hasRequiredSerialNumberError(gaugeModel)) {
      error = GaugeError.REQUIRED_SERIAL_NUMBER;
    }
    // 3. Check for serial number length
    else if (hasLengthError(gaugeModel)) {
      error = GaugeError.LENGTH;
    }
    // 4. Check for duplicate serial number
    else if (isSerialNumberDuplicated(gaugeModel, gaugeModels)) {
      error = GaugeError.DUPLICATED_SERIAL_NUMBER;
    }

    // Determine grid status based on error presence
    const grid_status = error ? GridStatus.KO : GridStatus.OK;

    return { ...gaugeModel, grid_status, error };
  });
}

export function isGaugeModified(gauges: SetGaugeModelUI[], row: SetGaugeModel): boolean {
  return (
    // Same serial_number used but the row which had the serial_number changed
    !!gauges.find(
      (item) =>
        item.initialSerialNumber === row.serial_number &&
        item.initialSerialNumber !== item.serial_number
    ) || // Same serial_number but on the same row
    !!gauges.find(
      (item) =>
        item.initialSerialNumber === row.serial_number &&
        item.initialSerialNumber === item.serial_number &&
        item.id === row.id
    )
  );
}
