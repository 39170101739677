import { TFunction } from 'i18next';
import Swal from 'sweetalert2';

import { FieldOperator } from '../../components/documentation/ApplicabilityFilter/utils';
import { MenuOptionsProps } from '../../components/shared';
import { ApplicabilityValueElement, PublicationStatus } from '../../interfaces/documentation';
import { JobType, TypeOfPart } from '../../utils/constants/royalties';

export interface SpecialConnection {
  connectionType: string;
  nbPin: number | null;
  nbBox: number | null;
}

// Define the TypeScript type for each part type
export interface PartType {
  type: string;
  nbSides: number | null;
  nbPin: number | null;
  nbBox: number | null;
  specialConnections?: SpecialConnection[];
}

// Create an array of part types based on the simplified data
export const partTypes: PartType[] = [
  {
    type: TypeOfPart.LIFTING_HANDLING_EQUIPMENT,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.WATERBUSHING_CIRCULATING_HEAD,
    nbSides: 1,
    nbPin: 1,
    nbBox: 0,
  },
  {
    type: TypeOfPart.TEST_CAP_PLUG,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.CROSSOVER,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.PUP_JOINT,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
    specialConnections: [
      {
        connectionType: 'T&C',
        nbPin: 2,
        nbBox: 2,
      },
      {
        connectionType: 'FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
      {
        connectionType: 'SEMI-FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
    ],
  },
  {
    type: TypeOfPart.NIPPLE,
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.COUPLING,
    nbSides: 1,
    nbPin: 0,
    nbBox: 2,
  },
  {
    type: TypeOfPart.FLOAT_SHOE,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FLOAT_COLLAR,
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: TypeOfPart.CASING_TUBING_HANGER,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FLOW_COUPLING_BLAST_JOINT,
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: TypeOfPart.THREE_WAY_CROSSOVER_Y_TOOL,
    nbSides: 3,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.BULL_PLUG_MULE_SHOE_REENTRY_GUIDE,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.LANDING_NIPPLE_NO_GO_LOCATOR,
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.SAFETY_VALVE,
    nbSides: 2,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.ECCENTRIC_COMPLETION_EQUIPMENT,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.PACKER,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.LINER_HANGER,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FULL_LENGTH_BASE_PIPE,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
    specialConnections: [
      {
        connectionType: 'T&C',
        nbPin: 2,
        nbBox: 2,
      },
      {
        connectionType: 'FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
      {
        connectionType: 'SEMI-FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
    ],
  },
  {
    type: TypeOfPart.OTHER,
    nbSides: null,
    nbPin: null,
    nbBox: null,
  },
];

export const handleFilterChange = (
  filters: ApplicabilityValueElement[],
  setFilters: React.SetStateAction<any>,
  controlValue: any,
  field: string
) => {
  // Check if the filter already exists
  const existingFilterIndex = filters.findIndex((filter) => filter.field === field);

  // If the selected value is empty, remove the existing filter if it exists
  if (controlValue === '' || controlValue === null || controlValue === undefined) {
    if (existingFilterIndex !== -1) {
      const updatedFilters = [...filters];
      updatedFilters.splice(existingFilterIndex, 1); // Remove the filter
      setFilters(updatedFilters);
    } else {
      setFilters(filters); // No changes needed if the filter doesn't exist
    }
    return;
  }

  // If the filter exists, update its value
  if (existingFilterIndex !== -1) {
    const updatedFilters = [...filters];
    updatedFilters[existingFilterIndex].value = controlValue;
    setFilters(updatedFilters);
  } else {
    // If the filter does not exist, add it to the filters array
    setFilters([...filters, { field, operator: FieldOperator.EQUAL, value: controlValue }]);
  }
};

// Create a default side configuration
export const createNewSide = () => ({
  end: '',
  connection: '',
  od_inch: '',
  weight: '' as any,
  application: '',
  special_bevel: false,
  oriented_connection: false,
});

export const createNewItem = () => ({
  type_of_part: '',
  connection: '',
  od_inch: '',
  weight: '',
  quantity: 1,
  unit: '',
  price: 1,
});

// Create sides from part type
export const createSidesFromPartType = (partType: PartType) => {
  const sides = [];
  for (let i = 0; i < Math.max(partType.nbSides, 1); i++) {
    const end = determineEndType(partType, i);
    sides.push({
      ...createNewSide(),
      end,
    });
  }
  return sides;
};

// Determine end from part type value
export const determineEndType = (partType: PartType, index: number) => {
  if (partType.nbPin !== null && partType.nbPin > 0 && index < partType.nbPin) {
    return 'PIN';
  }
  if (
    partType.nbBox !== null &&
    partType.nbBox > 0 &&
    index < partType.nbBox + (partType.nbPin || 0)
  ) {
    return 'BOX';
  }
  // If nbPin and nbBox are null, let the user choose
  return '';
};

export const addDefaultSides = (jobType: string, typeOfPart: string, replace: Function) => {
  if (jobType === JobType.NEW_PART) {
    const selectedPart = partTypes.find((part) => part.type === typeOfPart);
    if (selectedPart) {
      const sides = createSidesFromPartType(selectedPart);
      replace(sides);
    }
  } else if (jobType === JobType.REPAIR) {
    replace(createNewSide());
  }
};

export const handleAddSide = (append: Function, appendDisplayed: Function) => {
  append(createNewSide());
  appendDisplayed(createNewSide());
};

export const handleAddItem = (append: Function) => {
  append(createNewItem());
};

export const handleRemoveSide = (sideNb: number, remove: Function, removeDisplayed: Function) => {
  remove(sideNb);
  removeDisplayed(sideNb);
};

export const handleRemoveItem = (itemNb: number, remove: Function) => {
  remove(itemNb);
};

export const handleSwitchChange = (
  sideNb: number,
  name: string,
  checked: boolean,
  setValue: Function,
  trigger: Function
) => {
  setValue(`sides.${sideNb}.${name}`, checked);
  setValue(`displayed_sides.${sideNb}.${name}`, checked);
  trigger([`sides.${sideNb}.${name}`, `displayed_sides.${sideNb}.${name}`]);
};

export const handleCopySide = (
  sideNb: number,
  getValues: Function,
  append: Function,
  appendDisplayed: Function,
  update: Function,
  updateDisplayed: Function,
  jobType: string,
  typeOfPart: string
) => {
  const type = partTypes.find((partType) => partType.type === typeOfPart);
  const actualLength = getValues('sides')?.length;
  const copiedSide = getValues(`sides.${sideNb}`);
  if (actualLength < type?.nbSides || !type?.nbSides || jobType === JobType.REPAIR) {
    append(copiedSide);
    appendDisplayed(copiedSide);
  } else {
    if (actualLength === type?.nbSides) {
      for (let i = sideNb + 1; i < actualLength; i++) {
        const currentSide = getValues(`sides.${i}`);
        if (currentSide?.end?.length) {
          update(i, { ...copiedSide, end: currentSide.end });
          updateDisplayed(i, { ...copiedSide, end: currentSide.end });
        } else {
          update(i, copiedSide);
          updateDisplayed(i, copiedSide);
        }
      }
    }
  }
};

export const handleCopyItem = (itemNb: number, getValues: Function, append: Function) => {
  const copiedSide = getValues(`items.${itemNb}`);
  append(copiedSide);
};

export function getTitleContext(displayedSidesLength: number): string {
  return displayedSidesLength > 1 ? 'multiple' : 'one';
}

export const getOptionsMenu = (tFunction: TFunction, setStatus: Function): MenuOptionsProps[] => [
  {
    label: tFunction('publicationStatus.ACTIVE'),
    name: PublicationStatus.ACTIVE,
    handleItemClick: () => {
      setStatus(PublicationStatus.ACTIVE);
    },
  },
  {
    label: tFunction('publicationStatus.INACTIVE'),
    name: PublicationStatus.INACTIVE,
    handleItemClick: () => {
      setStatus(PublicationStatus.INACTIVE);
    },
  },
];

interface ConfirmChangeParams {
  jobType: string;
  typeOfPart: string;
  replace: Function;
  replaceDisplayed: Function;
  setPreviousJobType: Function;
  setPreviousTypeOfPart: Function;
  setIsRestoring: Function;
  setValue: Function;
  previousJobType: string;
  previousTypeOfPart: string;
}

export const confirmChange = ({
  jobType,
  typeOfPart,
  replace,
  replaceDisplayed,
  setPreviousJobType,
  setPreviousTypeOfPart,
  setIsRestoring,
  setValue,
  previousJobType,
  previousTypeOfPart,
}: ConfirmChangeParams) => {
  return Swal.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: 'The job details will be reset, are you sure you want to apply the modification ?',
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      // Apply default sides based on the new jobType and typeOfPart
      addDefaultSides(jobType, typeOfPart, replace);
      addDefaultSides(jobType, typeOfPart, replaceDisplayed);
      setPreviousJobType(jobType);
      setPreviousTypeOfPart(typeOfPart);
    } else {
      // Enter restoration mode and revert to previous values
      setIsRestoring(true);
      setValue('type_of_part', previousTypeOfPart);
      setValue('job_type', previousJobType);
    }
  });
};

interface ShouldConfirmChangeParams {
  jobType: string;
  typeOfPart: string;
  previousJobType: string;
  previousTypeOfPart: string;
  fields: any[];
}

export const shouldConfirmChange = ({
  jobType,
  typeOfPart,
  previousJobType,
  previousTypeOfPart,
  fields,
}: ShouldConfirmChangeParams): boolean => {
  const isJobTypeNewPart = jobType === JobType.NEW_PART;
  const hasPreviousJobType = previousJobType.length > 0;
  const hasPreviousTypeOfPart = previousTypeOfPart.length > 0;

  const typeOfPartChanged = typeOfPart !== previousTypeOfPart;

  const selectedPartType = partTypes.find((partType) => partType.type === typeOfPart) || {
    nbSides: 1,
    nbPin: null,
    nbBox: null,
    type: '',
  };

  const expectedSidesLength = createSidesFromPartType(selectedPartType).length;

  const sidesLengthChanged = expectedSidesLength !== fields.length;

  return !!(
    isJobTypeNewPart &&
    ((hasPreviousJobType && typeOfPartChanged) || (hasPreviousTypeOfPart && sidesLengthChanged))
  );
};
