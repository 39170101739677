import { AxiosResponse } from 'axios';

import { Address, PickupAddress, VegAddressPost } from '../../interfaces/address';
import { ManageShipmentsParams, ManageShipmentsResult } from '../../interfaces/shipping';
import { SaveUser } from '../../interfaces/user';
import {
  AxiosBookingDetails,
  BookingDetails,
  BookingDistinctFiltersParams,
  BookingDistinctFiltersResults,
  BookingPrice,
  BookingPriceParams,
  BookingsByFilterResults,
  BookingsByFiltersParams,
  ChangeStatusApi,
  DistinctProductApi,
  GaugeStockByLocation,
  GaugesBookingByStatusCount,
  LicenseeGauge,
  ManageBookingByStatusParams,
  ManageBookingGrid,
  ProductFilter,
  ProductsApi,
  ProductsParamsApi,
  RenewalReservation,
  ReplacementBookingLtr,
  ReservationDetailSets,
  SetBookingInformations,
  UpdateBookingGaugeLocation,
  UpdateBookingParams,
  VegBookingStockParams,
  VegPostBookingApi,
} from '../../interfaces/veg';
import { AddressType, BookingStatus, DeliveryMode, StatusFilters } from '../../utils';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttp, vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * fetch product by apply filter if exist
 * @param params?: ProductFilter
 * @returns DistrinctProductApi
 */
export function fetchDistinctProduct(
  params: ProductFilter
): Promise<AxiosResponse<DistinctProductApi, any>> {
  return vegHttpWithSpinner.get<DistinctProductApi>(endpoints.distinct_product, {
    params,
  });
}

/**
 * Post save new Gauge Booking
 * @param data VegGaugeBookingApi
 * @returns Promise id number
 */
export function saveVegBooking(data: VegPostBookingApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.booking, data);
}

/**
 * Get all Gauge's Booking by licensee_number
 * @param licensee_number number
 * @returns Promise ManageBooking[]
 */
export function getGaugesBooking(
  licensee_number?: number
): Promise<AxiosResponse<ManageBookingGrid[], any>> {
  return vegHttpWithSpinner.get<ManageBookingGrid[]>(endpoints.licensees_bookings(licensee_number));
}

/**
 * Get all Gauge's Booking by status
 * @param params ManageBookingByStatusParams
 * @param statusFilters BookingStatus[]
 */
// eslint-disable-next-line prettier/prettier
export function getGaugesBookingByStatus(
  params: ManageBookingByStatusParams,
  statusFilters: StatusFilters
): Promise<AxiosResponse<ManageBookingGrid[], any>> {
  const field = params?.sort?.[0]?.field;
  const renameSort = {
    [field]: field,
    od_txt: 'od_inch',
  };
  const { delivery_mode, licensee_number, depot_id, excluded_depot_booking, ...rest } = params;
  return vegHttpWithSpinner.post<ManageBookingGrid[]>(
    endpoints.bookings_by_status,
    {
      status_filters: statusFilters,
      delivery_mode: delivery_mode,
      licensee_number: licensee_number,
      depot_id: depot_id,
      excluded_depot_booking: excluded_depot_booking,
    },
    {
      params: {
        ...rest,
        ...{
          field: renameSort[field],
          sort: params?.sort?.[0]?.sort,
        },
      },
    }
  );
}

/**
 * Get all Gauge's Booking count
 * @param params BookingStatus[]
 * @param params DeliveryMode
 * @returns Promise ManageBooking[]
 */
// eslint-disable-next-line prettier/prettier
export function getGaugesBookingCountByStatus(
  statusFilters: BookingStatus[],
  licensee_number: number,
  deliveryMode?: DeliveryMode
): Promise<AxiosResponse<GaugesBookingByStatusCount, any>> {
  return vegHttpWithSpinner.post<GaugesBookingByStatusCount>(
    endpoints.bookings_count,
    statusFilters,
    { params: { delivery_mode: deliveryMode || null, licensee_number } }
  );
}

/**
 * Get Detail of Booking by booking_id
 * @returns Promise BookingDetail
 */
export function getBookingDetail(booking_id: string): Promise<AxiosBookingDetails> {
  return vegHttpWithSpinner.get<BookingDetails>(endpoints.bookings_by_id(booking_id));
}

/**
 * Get products by filter products and pagination
 * @param params ProductsParamsApi
 * @returns Promise ProductsApi
 */
export function getProducts(params: ProductsParamsApi): Promise<AxiosResponse<ProductsApi, any>> {
  return vegHttpWithSpinner.post<ProductsApi>(endpoints.products, params);
}

/**
 * fetch consignee addresses for a given licenseeId
 * @param licensee_number int
 * @param typeAddress string
 * @returns consignee addresses
 */
export function fetchAddresses(
  licensee_number: number,
  typeAddress: AddressType
): Promise<AxiosResponse<Address[], any>> {
  return vegHttp.get<Address[]>(
    endpoints.addresses_by_licensee_and_address_type(licensee_number, typeAddress)
  );
}

/**
 * get address by address_id
 * @param address_id int
 * @returns Address
 */
export function getAddress(address_id: number): Promise<AxiosResponse<Address, any>> {
  return vegHttp.get<Address>(endpoints.address_by_id(address_id));
}

/**
 * get pickup address by id
 * @param pickup_address_id int
 * @returns PickupAddress
 */
export function getPickupAddress(
  pickup_address_id: number
): Promise<AxiosResponse<PickupAddress, any>> {
  return vegHttp.get<PickupAddress>(endpoints.pickup_address_by_id(pickup_address_id));
}

/**
 * fetch pickup addresses
 * @returns pickup addresses
 */
export function fetchPickupAddresses(): Promise<AxiosResponse<PickupAddress[], any>> {
  return vegHttpWithSpinner.get<PickupAddress[]>(endpoints.pickup_addresses);
}

/**
 * post save new consignee or delivery addresses
 * @param data VegAddressApi
 * @returns Promise id number
 */
export function saveAddress(data: VegAddressPost): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.address, data);
}

/**
 * post save new pickup address
 * @param data PickupAddress
 * @returns Promise id number
 */
export function savePickupAddress(data: PickupAddress): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.pickup_addresses, data);
}

/**
 * put update pickup address
 * @param data PickupAddress
 * @returns Promise id number
 */
export function updatePickupAddress(
  pickupAddressId: number,
  data: PickupAddress
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.put<number>(endpoints.pickup_address_by_id(pickupAddressId), data);
}

/**
 * Get booking price veg
 * @param data BookingPriceParams
 * @returns Promise BookingPrice
 */
export function getBookingPrice(
  params: BookingPriceParams
): Promise<AxiosResponse<BookingPrice, any>> {
  return vegHttpWithSpinner.get<BookingPrice>(endpoints.booking_prices, { params });
}

/**
 * Get the current booking status
 * @param params booking_id
 * @returns Promise BOOKING_STATUS
 */
export function getCurrentBookingStatus(
  booking_id: number
): Promise<AxiosResponse<BookingStatus, any>> {
  return vegHttp.get<BookingStatus>(endpoints.booking_status_by_id(booking_id));
}

/**
 * Get the current booking status
 * @param params VegBookingStockParams
 * @returns Promise number[]
 */
export function getBookingStock(
  params: VegBookingStockParams
): Promise<AxiosResponse<number[], any>> {
  return vegHttpWithSpinner.get<number[]>(endpoints.bookings_stocks, { params });
}

/**
 * Get the stock of gauge for all site
 * @returns Promise GAUGE_STOCK
 */
export function getBookingStocks(
  params: VegBookingStockParams
): Promise<AxiosResponse<GaugeStockByLocation[], any>> {
  return vegHttp.get<GaugeStockByLocation[]>(endpoints.bookings_stocks_by_location, { params });
}

/**
 * Change booking status to a new one
 * @param data ChangeStatusApi
 * @returns Promise int number
 */
export function updateBookingStatus(data: ChangeStatusApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.booking_status, {
    ...data,
    booking_id: data.id,
  });
}

/**
 * Change set status to a new one
 * @param data ChangeStatusApi
 * @returns Promise int number
 */
export function updateSetStatus(data: ChangeStatusApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.sets_status, {
    ...data,
    set_id: data.id,
  });
}

/**
 * Get set informations from booking id
 * @param params booking_id
 * @returns Promise SetBookingInformations
 */
export function getSetBookingInformations(
  booking_id: number
): Promise<AxiosResponse<SetBookingInformations, any>> {
  return vegHttp.get<SetBookingInformations>(endpoints.sets_by_booking_id(booking_id));
}

/**
 * Get the list of set that matched with connections params
 * @returns Promise ReservationDetailSets[]
 */
export function getMatchedSets(
  params: VegBookingStockParams
): Promise<AxiosResponse<ReservationDetailSets[], any>> {
  return vegHttp.get<ReservationDetailSets[]>(endpoints.sets_by_product, { params });
}

/**
 * Update booking
 * @returns Promise SetBookingInformations
 */
export function updateBooking(
  bookingId: number,
  params: UpdateBookingParams
): Promise<AxiosResponse<SetBookingInformations, any>> {
  return vegHttpWithSpinner.patch<SetBookingInformations>(
    endpoints.bookings_by_id(bookingId),
    params
  );
}

/**
 * Get the list of distinct filters
 * @param params BookingDistinctFiltersParams
 * @returns Promise BookingDistinctFiltersResults
 */
export function getDistinctBookingFilters(
  params: BookingDistinctFiltersParams
): Promise<AxiosResponse<BookingDistinctFiltersResults, any>> {
  return vegHttpWithSpinner.post<BookingDistinctFiltersResults>(
    endpoints.bookings_distinct,
    params
  );
}

/**
 * Get the list of bookings filtered
 * @param params BookingsByFiltersParams
 * @returns Promise BookingByFilterResults
 */
export function getBookings(
  params: BookingsByFiltersParams
): Promise<AxiosResponse<BookingsByFilterResults, any>> {
  return vegHttpWithSpinner.post<BookingsByFilterResults>(endpoints.bookings, params);
}

/**
 * Get the current rentals of a licensee
 * @param licensee_number
 * @returns Promise LicenseeGauge[]
 */
export function getLicenseeGauges(
  licensee_number: number,
  statusFilters: StatusFilters
): Promise<AxiosResponse<LicenseeGauge[], any>> {
  return vegHttpWithSpinner.post<LicenseeGauge[]>(
    endpoints.licensees_gauges(licensee_number),
    statusFilters
  );
}

/**
 * Get the current rentals count of a licensee
 * @param licensee_number
 * @param statusFilters
 * @returns Promise LicenseeGauge[]
 */
export function getLicenseeGaugesCount(
  licensee_number: number,
  statusFilters: BookingStatus[]
): Promise<AxiosResponse<GaugesBookingByStatusCount, any>> {
  return vegHttpWithSpinner.post<GaugesBookingByStatusCount>(
    endpoints.licensees_gauges_count(licensee_number),
    statusFilters
  );
}

/**
 * Generate certificate of calibration in pdf file.
 * @param set_id number
 * @returns Promise blob
 */
export function exportCalibrationCertificate(set_id: number): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.get<Blob>(endpoints.export_calibration_certificate, {
    params: { set_id },
    responseType: 'blob',
  });
}

/**
 * Generate packing list in pdf file.
 * @param set_id number
 * @returns Promise blob
 */
export function downloadPackingList(set_id: number): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.get<Blob>(endpoints.export_set_packing_list, {
    params: { set_id },
    responseType: 'blob',
  });
}

// /**
//  * Post save new invoice
//  * @param data SaveInvoice
//  * @returns Promise id number
//  */
// export function createInvoice(data: Invoice): Promise<AxiosResponse<number, any>> {
//   return vegHttp.post<number>(endpoints.invoices, data);
// }

/**
 * Post save new user
 * @param data SaveUser
 * @returns Promise id number
 */
export function createUser(data: SaveUser): Promise<AxiosResponse<any, any>> {
  return vegHttpWithSpinner.post<any>(endpoints.users, data);
}

/**
 * Get all Shipments by status
 * @param params ManageShipmentsParams
 */
export function getShipmentsByStatus(
  params: ManageShipmentsParams
): Promise<AxiosResponse<ManageShipmentsResult[], any>> {
  return vegHttpWithSpinner.post<ManageShipmentsResult[]>(endpoints.shipments, params);
}

/**
 * Post to extend reservation LTR by six months
 * @param params RenewalReservation
 * @return Promise id number
 */
export function extendReservationLtr(
  params: RenewalReservation
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.renewal_booking, params);
}

/**
 * Replacement booking LTR
 * @param params ReplacementBookingLtr
 * @return Promise id number for new booking replacement
 */
export function replacementBookingLtr(
  params: ReplacementBookingLtr
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.replacement_booking, params);
}

/**
 * Generate Gauging procedure summary in pdf file.
 * @param booking_id number
 * @returns Promise blob
 */
export function exportGpsGaugingSummary(booking_id: number): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.get<Blob>(endpoints.export_gauging_summary, {
    params: { booking_id },
    responseType: 'blob',
  });
}

/**
 * Update booking depot
 * @param params UpdateBookingGaugeLocation
 * @return Promise id number depot id
 */
export function updateBookingDepot(
  params: UpdateBookingGaugeLocation
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.put<number>(endpoints.booking_depot, params);
}

/**
 * Get all Rental data by BookingsByFiltersParams
 * @param params BookingsByFiltersParams
 */
export function exportCsvRentalData(
  params: BookingsByFiltersParams
): Promise<AxiosResponse<Blob, any>> {
  return vegHttp.post<Blob>(endpoints.export_csv_rental_data, params, {
    responseType: 'blob',
  });
}
